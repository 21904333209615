import { graphql } from "gatsby";
import React from 'react'
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import MetaImage from "../components/MetaImage";


const Article = ({ data, location }) => {
    const article = data.article;

    return (
        <Layout>
            {article.thumbnail && <MetaImage image={article.thumbnail.metaImage} location={location} />}
            <Helmet>
                <title>{`${article.title}`}</title>
            </Helmet>
            <h1>{article.title}</h1>

            <div dangerouslySetInnerHTML={({ __html: article.content })}></div>
        </Layout>
    )
};

export default Article;


export const articleQuery =
    graphql`
  query($id: String!) {
    article(
        id: {eq: $id }
    ) {
        title
        categories
        content
        thumbnail {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
                src
              }
            }
            metaImage: childImageSharp {
                fixed(width: 1200, height: 1200) {
                    ...GatsbyImageSharpFixed
                    src
                }
            }
        }
    }
 
  }
`;